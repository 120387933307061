import { useEffect, useState } from 'react';
import jwt from "jsonwebtoken";

/**
 * This function captures the "code" querystring returned from an OAuth call
 * and stores it in react state, then clears the querystring for security.
 * 
 * @returns {Hook}
 */
function useGuestWebexOAuth() {
  const [guestAccessToken, setGuestAccessToken] = useState('');
  const { hash } = window.location;

  const payload = {
    "sub": "guest-user-7349",
    "name": "NDPS Patient",
    "iss": process.env.REACT_APP_WEBEX_GUEST_ID
  };

  const guestToken = jwt.sign(
    payload,
    Buffer.from(process.env.REACT_APP_WEBEX_GUEST_SECRET, 'base64'),
    { expiresIn: '1h' }
  );

  useEffect(() => {
    async function getGuestAccessToken() {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${guestToken}`
        }
      };

      const tokenResponse = await fetch('https://webexapis.com/v1/jwt/login', requestOptions).then(response => response.json());

      setGuestAccessToken(tokenResponse.token);
    }
    getGuestAccessToken();
  }, []);

  return guestAccessToken;
}

export default useGuestWebexOAuth;