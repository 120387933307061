import React, { useEffect, useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Auth from '../api/OAuthLink';
import NewAppointmentDialog from './newAppointmentDialog';
import AppointmentsPopoverHeader from '../components/appointmentsPopoverHeader'
import AccessTokenContext from '../providers/AccessTokenContext';
import AppointmentContext from '../providers/AppointmentContext';
import AppointmentList from '../components/appointmentList';
import { GetScheduledMeetingsByDay } from '../api/services/meetings';
import useCurrentUri from '../util/useCurrentUri';

const AppointmentsPopover = (props) => {
  const { handleClose, open } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [appointments, setAppointments] = useContext(AppointmentContext);

  const redirectURI = useCurrentUri();
  const webexToken = useContext(AccessTokenContext);

  const handleCreateNewAppointment = () => {
    setIsOpen(isOpen ? false : true);
  }

  useEffect(async () => {
    const response = await GetScheduledMeetingsByDay(webexToken);
    setAppointments(response);
  }, [webexToken, open]);

  return (
    <React.Fragment>
      <Popover
        open={open}
        marginThreshold={0}
        anchorReference="anchorPosition"
        anchorPosition={{ left: 0, top: 56 }}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: 'calc(100dvh - 56px)',
            width: '485px'
          }
        }}
      >
        {webexToken ?
          (
            <React.Fragment>
              <AppointmentsPopoverHeader
                handleCreateNewAppointment={handleCreateNewAppointment}
              />
              <AppointmentList
                appointments={appointments}
                handleClose={handleClose}
              />
            </React.Fragment>
          ) :
          (
            <Auth
              clientID={process.env.REACT_APP_WEBEX_CLIENT_ID}
              loginText="Login to Webex"
              redirectURI={redirectURI}
              webexAPIBaseURL={process.env.REACT_APP_WEBEX_BASE_URL}
            />
          )
        }
      </Popover>

      <NewAppointmentDialog
        isOpen={isOpen}
        handleIsOpen={setIsOpen}
      />
    </React.Fragment>
  );
}

export default AppointmentsPopover;