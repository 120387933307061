import React, { useState } from 'react'

const AuthContext = React.createContext(null);

export const AuthProvider = (props) => {
    const [isAuth, setIsAuth] = useState(false); 

    return (
        <AuthContext.Provider
            value={[
                isAuth,
                setIsAuth
            ]}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;