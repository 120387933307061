import React, { useState } from 'react'

const AppointmentContext = React.createContext(null);

export const AppointmentProvider = (props) => {
    const [appointments, setAppointments] = useState({}); 

    return (
        <AppointmentContext.Provider
            value={[
                appointments,
                setAppointments
            ]}
        >
            {props.children}
        </AppointmentContext.Provider>
    )
}

export default AppointmentContext;