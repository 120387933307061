import React, { useContext, useState, useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import queryString from 'query-string';
import {
    Box, List, ListItem, ListItemIcon,
    Tooltip, Typography
} from '@mui/material';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import StarsIcon from '@mui/icons-material/Stars';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGuestWebexOAuth from '../api/useGuestWebexOAuth';
import AccessTokenContext from '../providers/AccessTokenContext';
import SipAddressContext from '../providers/SipAddressContext';
import WebexMeeting from '../components/WebexMeeting';
import Whiteboard from '../containers/whiteboard';
import NavigationBar from '../containers/navigationBar'
import Login from '../components/login'
import AuthContext from '../providers/AuthContext'

const Main = ({ onLogout }) => {
    const { client, sipAddress } = queryString.parse(window.location.search);
    const webexTokenHost = useContext(AccessTokenContext);
    const [isAuth, setIsAuth] = useContext(AuthContext);
    const webexTokenClient = useGuestWebexOAuth();
    const [sipAddressHost, setSipAddressHost] = useContext(SipAddressContext);
    const [sipAddressClient, setSipAddressClient] = useState(sipAddress);
    const [gameOpen, setGameOpen] = useState(false);
    const [whiteboardOpen, setWhiteboardOpen] = useState(false);

    const [sizes, setSizes] = useState(['100%', '0%']);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        // Define the callback function to be executed when mutations are observed
        const callback = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach((node) => {
                        if (node.nodeName === 'H5' && node.textContent.includes("You've successfully left the meeting")) {
                            if (!sipAddressHost && sipAddressClient) {
                                window.location.reload();
                            } else {

                                const url = new URL(window.location.href);

                                // Remove the hash from the URL if it exists
                                url.hash = '';
    
                                // Append the webexTokenHost variable value as a search param
                                url.searchParams.set('reloadToken', webexTokenHost);
    
                                // Navigate to the new URL
                                window.location.href = url.toString();
                            }

                        }
                    });
                }
            }
        };

        // Create a new MutationObserver instance
        const observer = new MutationObserver(callback);

        // Select the target node to observe
        const targetNode = document.getElementById('webex-container'); // Replace with the actual ID or class of the Webex component container

        // Set the configuration for the observer
        const config = { childList: true, subtree: true };

        // Start observing the target node
        if (targetNode) observer.observe(targetNode, config);

        // Clean up the observer on component unmount
        return () => {
            if (targetNode) observer.disconnect();
        };
    }, [webexTokenHost]);


    useEffect(() => {

        setSizes([`100%`, `0%`]);

        const handleWindowResize = () => {
            if (sipAddressClient || sipAddressHost) {
                //console.log('chaning');
                const gameWidth = window.innerHeight - 55;
                setSizes([`${window.innerWidth - gameWidth}px`, `${gameWidth}px`]);
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    useEffect(() => {
        if (sipAddressClient || sipAddressHost) {
            const gameWidth = window.innerHeight - 55;
            setSizes([`${window.innerWidth - gameWidth}px`, `${gameWidth}px`]);

            setGameOpen(true);
        }

    }, [sipAddressClient, sipAddressHost])

    const { unityProvider, sendMessage, isLoaded } = useUnityContext({
        loaderUrl: "build/test-new.loader.js",
        dataUrl: "build/test-new.data",
        frameworkUrl: "build/test-new.framework.js",
        codeUrl: "build/test-new.wasm",
    });

    const drawerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Pushes controls to the bottom
        height: 'calc(100dvh - 56px)',
    };

    const buttonStyle = {
        minWidth: '100%', // Make the button full width
        justifyContent: 'center', // Center the icon
        paddingLeft: '6px',
    };

    const labelStyle = {
        writingMode: 'vertical-rl',
        textOrientation: 'upright',
        margin: 'auto',
        fontSize: '12px',
    };

    const logoutAndResetWidths = () => {
        setSizes(['100%', '0%']);
        onLogout();
    }

    const toggleFeature = (feature) => {
        if (sipAddressClient || sipAddressHost) {
            setGameOpen(feature === 'game' ? !gameOpen : false);
            setWhiteboardOpen(feature === 'whiteboard' ? !whiteboardOpen : false);

            if (feature === 'game' && gameOpen && !whiteboardOpen) {
                setSizes(['100%', '0%']);
                return;
            }
            else if (feature === 'whiteboard' && !gameOpen && whiteboardOpen) {
                setSizes(['100%', '0%']);
                return;
            } else {
                if (feature === 'game') {
                    const gameWidth = windowSize[1] - 55;
                    setSizes([`${windowSize[0] - gameWidth}px`, `${gameWidth}px`]);
                } else {
                    setSizes(['50%', '50%']);
                }
            }
        }
    };

    useEffect(() => {
        const setUnityParams = () => {
            if (isLoaded && (sipAddressHost || sipAddressClient)) {
                sendMessage("SocketIO", "SetRoomID", JSON.stringify({
                    id: client ? sipAddressClient : sipAddressHost,
                    type: client ? "Guest" : "Host"
                }));
            }
        };
            setUnityParams();
        }, [sipAddressHost, sipAddressClient, isLoaded]);

    const movePlayer = (direction) => sendMessage("SocketIO", `move${direction.charAt(0).toUpperCase() + direction.slice(1)}`);

    const isFeatureAvailable = () => (webexTokenHost && (sipAddressClient || sipAddressHost)) || (webexTokenClient && (sipAddressClient || sipAddressHost));

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100dvh',
                overflow: 'hidden',
            }}
        >
            <>
                <NavigationBar
                    onLogout={logoutAndResetWidths}
                    isClient={client}
                    isAuth={isAuth} />

                {(!client && localStorage.getItem("loggedIn") !== "true") ?
                    <div>
                        <Login />
                    </div>
                    :
                    <Box sx={{ display: 'flex', height: "calc(100dvh - 56px)", width: '100%' }}>
                        <Box sx={{ display: 'flex', width: 'calc(100dvw - 55px)' }}>
                            <Box sx={{ width: sizes[0] }}>
                                <WebexMeeting
                                    webexToken={client ? webexTokenClient : webexTokenHost}
                                    destination={client ? sipAddressClient : sipAddressHost}
                                />
                            </Box>
                            <Box sx={{ width: sizes[1] }}>
                                {(sipAddressClient || sipAddressHost) &&
                                    <Unity unityProvider={unityProvider} tabIndex={1}
                                        style={{
                                            height: 'calc(100dvh - 56px)',
                                            width: '100%',
                                            display: gameOpen ? 'block' : 'none'
                                        }}
                                    />}
                                <Whiteboard
                                    display={whiteboardOpen ? 'block' : 'none'}
                                    destination={client ? sipAddressClient : sipAddressHost}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '55px' }}>
                            <List style={drawerStyle}>
                                <div>
                                    <ListItem button>
                                        <Tooltip title={isFeatureAvailable() ? 'Game' : 'Connect to appointment for Game tab to be available.'}>
                                            <ListItemIcon>
                                                <VideogameAssetIcon color={isFeatureAvailable() ? 'primary' : 'disabled'} onClick={() => toggleFeature('game')} />
                                            </ListItemIcon>
                                        </Tooltip>
                                    </ListItem>
                                    <ListItem button>
                                        <Tooltip title={isFeatureAvailable() ? 'Whiteboard' : 'Connect to appointment for Whiteboard tab to be available.'}>
                                            <ListItemIcon>
                                                <StarsIcon color={isFeatureAvailable() ? 'primary' : 'disabled'} onClick={() => toggleFeature('whiteboard')} />
                                            </ListItemIcon>
                                        </Tooltip>
                                    </ListItem>
                                </div>
                                {gameOpen && (
                                    <Box sx={{ display: 'flex', paddingBottom: '25px' }}>
                                        <Typography style={labelStyle}>Game Controls</Typography>
                                        <div>
                                            <ListItem button onClick={() => movePlayer('up')} style={buttonStyle}>
                                                <ArrowUpwardIcon />
                                            </ListItem>
                                            <ListItem onClick={() => movePlayer('down')} button style={buttonStyle}>
                                                <ArrowDownwardIcon />
                                            </ListItem>
                                            <ListItem onClick={() => movePlayer('right')} button style={buttonStyle}>
                                                <ArrowForwardIcon />
                                            </ListItem>
                                            <ListItem onClick={() => movePlayer('left')} button style={buttonStyle}>
                                                <ArrowBackIcon />
                                            </ListItem>
                                        </div>
                                    </Box>
                                )}
                            </List>
                        </Box>
                    </Box>
                }
            </>
        </div>
    );
};

export default Main;

