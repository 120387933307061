import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import AppointmentListItem from '../containers/appointmentListItem';

const AppointmentList = (props) => {
    const { appointments, handleClose } = props;

    return (
        <Box sx={{ m: 2 }} style={{ overflow: "scroll", marginLeft: '0px' }}>
            <Stack spacing={2} >
                {
                    appointments && Object.keys(appointments).map(key => {
                        let dateLabel = new Date(key);
                        dateLabel.setDate(dateLabel.getDate() + 1);

                        let label = dateLabel.toDateString();

                        return (
                            <div>
                                <Divider className="date-divider" orientation="horizontal" component="div" sx={{ flexGrow: 1, ml: 2, mb: 2 }} >{label}</Divider>
                                {
                                    appointments[key].map(appointment => {
                                        return (
                                            <AppointmentListItem
                                                appointment={appointment}
                                                handleClose={handleClose}
                                            />
                                        )
                                    })}
                            </div>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}

export default AppointmentList;