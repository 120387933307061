import Box from '@mui/material/Box'
import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import io from 'socket.io-client';
import ClearIcon from '@mui/icons-material/Clear';
import './whiteboard.css';

function Whiteboard({ destination, display }) {
  const canvasRef = useRef(null);
  const colorsRef = useRef(null);
  const socketRef = useRef();
  const [color, setColor] = useState("black");

  const handleClear = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.restore();

    socketRef.current.emit('clear', {
      sipAddress: destination
    });
  }

  const remoteClear = () => {

    if(canvasRef) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.restore();
    }
  }

  useEffect(() => {
    // --------------- getContext() method returns a drawing context on the canvas-----


if(canvasRef) {
  const canvas = canvasRef.current;
  const context = canvas.getContext('2d');

  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;

  // ----------------------- Colors --------------------------------------------------

  const colors = document.getElementsByClassName('color');

  // set the current color
  const current = {
    color: 'black',
  };

  // helper that will update the current color
  const onColorUpdate = (e) => {
    current.color = e.target.className.split(' ')[1];
    setColor(current.color);

  };

  // loop through the color elements and add the click event listeners
  for (let i = 0; i < colors.length; i++) {
    colors[i].addEventListener('click', onColorUpdate, false);
  }
  let drawing = false;

  // ------------------------------- create the drawing ----------------------------
  const toolbarOffest = 55;

  const drawLine = (x0, y0, x1, y1, color, emit) => {
    const w = canvas.offsetWidth;
    const h = canvas.offsetHeight;

    const xOffset = window.innerWidth - w - toolbarOffest;
    const yOffset = window.innerHeight - h;

    context.beginPath();
    context.moveTo(x0 - xOffset, y0 - yOffset);
    context.lineTo(x1 - xOffset, y1 - yOffset);
    context.strokeStyle = color;
    context.lineWidth = 2;
    context.stroke();
    context.closePath();
    if (!emit) { return; }

    socketRef.current.emit('drawing', {
      sipAddress: destination,
      x0: x0 / w,
      y0: y0 / h,
      x1: x1 / w,
      y1: y1 / h,
      color,
    });
  };

  // ---------------- mouse movement --------------------------------------



  const onMouseDown = (e) => {
    drawing = true;
    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
  };

  const onMouseMove = (e) => {
    if (!drawing) { return; }
    drawLine(current.x, current.y, e.clientX || e.touches[0].clientX, e.clientY || e.touches[0].clientY, current.color, true);
    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
  };

  const onMouseUp = (e) => {
    if (!drawing) { return; }
    drawing = false;
  };

  // ----------- limit the number of events per second -----------------------

  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime();
    return function () {
      const time = new Date().getTime();

      if ((time - previousCall) >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    };
  };

  // -----------------add event listeners to our canvas ----------------------

  canvas.addEventListener('mousedown', onMouseDown, false);
  canvas.addEventListener('mouseup', onMouseUp, false);
  canvas.addEventListener('mouseout', onMouseUp, false);
  canvas.addEventListener('mousemove', throttle(onMouseMove, 10), false);

  // Touch support for mobile devices
  canvas.addEventListener('touchstart', onMouseDown, false);
  canvas.addEventListener('touchend', onMouseUp, false);
  canvas.addEventListener('touchcancel', onMouseUp, false);
  canvas.addEventListener('touchmove', throttle(onMouseMove, 10), false);

  const onResize = () => {
    const curWidth = canvas.width ? canvas.width : 0;
    const curHeight = canvas.height ? canvas.height : 0;

    const width = canvas.offsetWidth ? canvas.offsetWidth : (window.innerWidth / 2);
    const height = canvas.offsetHeight ? canvas.offsetHeight : (window.innerHeight - 64);

    if (curWidth !== width || curHeight !== height) {

      const xRatio = curWidth / width;
      const yRatio = curHeight / height;


      if (curWidth !== 0 && curHeight !== 0) {

        const tempContext = context.getImageData(0, 0, curWidth, curHeight);

        canvas.width = width;
        canvas.height = height;

        context.putImageData(tempContext, 0, 0)

        context.scale(xRatio, yRatio);
      } else {
        canvas.width = width;
        canvas.height = height;
      }


      return true;
    }

    return false;
  };

  window.addEventListener('resize', onResize, false);
  onResize();

  // ----------------------- socket.io connection ----------------------------
  const onDrawingEvent = (data) => {
    const w = canvas.offsetWidth;
    const h = canvas.offsetHeight;

    drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color);
  }

  if (destination) {
    socketRef.current = io('https://alb.eklectus.com/whiteboard');
    socketRef.current.emit('joinWhiteboardRoom', { sipAddress: destination });
    socketRef.current.on('drawing', onDrawingEvent);
    socketRef.current.on('remoteClear', remoteClear);
  }
}

  }, [destination]);

  return (
    <Box style={{ height: "calc(100dvh - 56px)", width: '100%', display: display, background: "linear-gradient(#f0f0f0,#c8c8c8)" }}>
      <Typography style={{ display: destination ? "none" : "block" }} variant="h6" component="div" sx={{ flexGrow: 1, color: 'white', mt: 5, ml: 5 }}>
        Game will become available after connecting to an appointment poopy.
      </Typography>
      <div style={{ display: destination ? "flex" : "none" }} ref={colorsRef} className="colors">
        <div className={color === "black" ? "color black color-selected" : "color black"} />
        <div className={color === "red" ? "color red color-selected" : "color red"} />
        <div className={color === "green" ? "color green color-selected" : "color green"} />
        <div className={color === "blue" ? "color blue color-selected" : "color blue"} />
        <div className={color === "yellow" ? "color yellow color-selected" : "color yellow"} />
        <ClearIcon onClick={handleClear} className={"clr-btn"} style={{ width: "32px", height: "32px" }} />
      </div>
      <canvas style={{ display: destination ? "block" : "hidden", height: "calc(100dvh - 56px)", width: "calc(50dvw - 27.5px)" }} ref={canvasRef} className="whiteboard" />
    </Box>
  )
}
export default Whiteboard;