import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Stack from '@mui/material/Stack';
import AccessTokenContext from '../providers/AccessTokenContext';
import { GetScheduledMeetingsByDay } from '../api/services/meetings';
import AppointmentContext from '../providers/AppointmentContext';

export default function NewAppointmentDialog({ isOpen, handleIsOpen }) {
    const [title, setTitle] = useState('');
    const [password, setPassword] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [validationErrors, setValidationErrors] = useState({});
    const webexToken = useContext(AccessTokenContext);
    const [appointments, setAppointments] = useContext(AppointmentContext);

    const handleValidation = () => {
        const errors = {};
        const now = new Date();
        if (password === '' || password.toLowerCase().includes('password') || password.length < 8) errors.password = 'Invalid password. Min 8 chars. Cannot include `password`.';
        if (title === '' || title.length < 6 || title.length > 80) errors.title = 'Invalid title. Min 6 chars, max of 80 chars.';
        if (startTime <= now) errors.startTime = 'Start time must be in the future.';
        if (endTime <= now) errors.endTime = 'End time must be in the future.';
        if (endTime <= startTime) errors.endTime = 'End time must be after start time.';

        return errors;
    };

    const handleScheduleNewAppointment = async () => {
        const validation = handleValidation();
        if (Object.keys(validation).length > 0) {
            setValidationErrors(validation);
            return;
        }

        const payload = {
            enabledAutoRecordMeeting: false,
            allowAnyUserToBeCoHost: false,
            enabledJoinBeforeHost: false,
            enableConnectAudioBeforeHost: false,
            excludePassword: false,
            publicMeeting: false,
            enableAutomaticLock: false,
            allowFirstUserToBeCoHost: false,
            allowAuthenticatedDevices: false,
            sendEmail: false,
            title,
            password,
            start: startTime.toISOString(),
            end: endTime.toISOString(),
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${webexToken}`
            },
            body: JSON.stringify(payload),
        };

        try {
            const response = await fetch('https://webexapis.com/v1/meetings', requestOptions);
            if (!response.ok) throw new Error();

            const result = await response.json();
            const data = await GetScheduledMeetingsByDay(webexToken);
            setAppointments(data);
            handleIsOpen(false);
            resetForm();
        } catch (error) {
            setValidationErrors({ general: 'Unable to process the request. Please try again later.' });
        }
    };

    const resetForm = () => {
        setTitle('');
        setPassword('');
        setStartTime(new Date());
        setEndTime(new Date());
        setValidationErrors({});
    };

    return (
        <Dialog open={isOpen} onClose={() => handleIsOpen(false)}>
            <DialogTitle>Schedule New Appointment</DialogTitle>
            <DialogContent>
                <DialogContentText>Complete the form to schedule a new appointment.</DialogContentText>
                <TextField autoFocus margin="dense" id="title" label="Title" fullWidth variant="standard" value={title} onChange={e => setTitle(e.target.value)} error={!!validationErrors.title} helperText={validationErrors.title} tabIndex={2} />
                <TextField margin="dense" id="password" label="Password" fullWidth variant="standard" value={password} onChange={e => setPassword(e.target.value)} error={!!validationErrors.password} helperText={validationErrors.password} tabIndex={3} />
                <Stack spacing={2} sx={{ pt: 3 }} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker renderInput={props => <TextField {...props} error={!!validationErrors.startTime} helperText={validationErrors.startTime} />} label="Start Time" value={startTime} onChange={setStartTime} sx={{ flexGrow: 1 }} />
                        <DateTimePicker renderInput={props => <TextField {...props} error={!!validationErrors.endTime} helperText={validationErrors.endTime} />} label="End Time" value={endTime} onChange={setEndTime} sx={{ flexGrow: 1 }} />
                    </LocalizationProvider>
                </Stack>
                {validationErrors.general && <DialogContentText color="error">{validationErrors.general}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleIsOpen(false)}>Cancel</Button>
                <Button onClick={handleScheduleNewAppointment}>Schedule</Button>
            </DialogActions>
        </Dialog>
    );
}
