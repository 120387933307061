import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';

export default function AppointmentDetailPopover(props) {
  const {
    title,
    date,
    startTime,
    endTime,
    password,
    link,
  } = props;

  // end AppointmentControls

  // appoinment detail popover
  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const path = `${window.location.origin}/?client=true&sipAddress=${link}\n\nPassword:\n${password}`

  const handleCopyMeetingInfo = () => {
    let str = `Meeting Info\n\nWhat:\n${title}\n\nWhen:\n${date}\n${startTime} - ${endTime}\n\nMeeting Link:\n${path}`;
    copyText(str);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const shortPath = `${window.location.origin}/?client=true&sipAddress=${link}`

  return (
    <React.Fragment>
      <IconButton aria-label="appointment information" variant="contained" onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          style: {
            width: "420px",
            overflow: "visible"
          }
        }}
        sx={{
          left: "20px",
          overflow: "visible"
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "10px",
            "&::before": {
              backgroundColor: "white",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              transform: "rotate(45deg)",
              left: "-6px",
              top: "3px"
            }
          }}
        />
        <Box sx={{ m: 2 }}>
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{title}</Typography>
          <Typography variant="subtitle1">{date}</Typography>
          <Typography variant="subtitle2" sx={{ pb: 2 }}>{`${startTime} - ${endTime}`}</Typography>

          <Stack spacing={2} direction="row">
            <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 'bold' }}>Meeting Info</Typography>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton onClick={handleCopyMeetingInfo} aria-label="copy meeting info">
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Stack>


          <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>Meeting Link</Typography>
          <Typography variant="body2" sx={{ pb: 1 }}>{shortPath}</Typography>
          <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>Meeting Password</Typography>
          <Typography variant="body2" sx={{ pb: 2 }}>{password}</Typography>
        </Box>
      </Popover>
    </React.Fragment>
  );
}
