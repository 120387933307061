import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AppointmentsPopover from './appointmentsPopover';
import NavigationMenu from '../components/navigationMenu'
import AccessTokenContext from '../providers/AccessTokenContext';
import Logo from "../brand/logo1.png";
import styles from './navigationBar.module.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const NavigationBar = ({ isClient, onLogout }) => {
  const webexToken = useContext(AccessTokenContext);
  const [appointmentPopoverOpen, setAppointmentPopoverOpen] = useState(!isClient && webexToken !== '');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleAppointmentPopoverToggle = (event) => {
    setAppointmentPopoverOpen(appointmentPopoverOpen ? false : true);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar sx={{ backgroundColor: '#1275bc', maxHeight: '56px', boxShadow: 'none' }} position="static">
        <Toolbar>
          {!isClient && webexToken ?
            <IconButton
              onClick={handleAppointmentPopoverToggle}
              size="small"
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
            >
              <MenuIcon fontSize='small' />
            </IconButton>
            :
            <></>
          }
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img className={styles.logo} src={Logo} />
          </Typography>
          {!isClient && webexToken ?
            <IconButton
              onClick={handleMenuClick}
              size="small"
              sx={{ marginRight: '-16px' }}
            >
              <AccountCircleIcon sx={{ width: 32, height: 32, color: 'white' }} />
            </IconButton>
            :
            <></>
          }
        </Toolbar>
      </AppBar>
      <NavigationMenu
        onLogout={onLogout}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMenuClose}
      />
      <AppointmentsPopover
        open={appointmentPopoverOpen}
        handleClose={handleAppointmentPopoverToggle}
      />
    </React.Fragment>
  );
}

export default NavigationBar;