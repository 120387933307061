import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';

const appointmentsPopoverHeader = (props) => {
    const { handleCreateNewAppointment } = props;
    return (
        <Stack sx={{ p: 2 }} spacing={2} direction="row">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Appointments
            </Typography>
            <Button variant="contained" onClick={handleCreateNewAppointment}><AddIcon />New Appointment</Button>
        </Stack>
    )
}

export default appointmentsPopoverHeader;