import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppointmentControls from '../components/appointmentControls';
import { formatAMPM } from '../util/date';
import { DeleteAppointmentByID } from '../api/services/meetings';
import SipAddressContext from '../providers/SipAddressContext';
import AccessTokenContext from '../providers/AccessTokenContext';
import AppointmentContext from '../providers/AppointmentContext';
import { GetScheduledMeetingsByDay } from '../api/services/meetings';

export default function AppointmentListItem(props) {
  const {
    id,
    link,
    password,
    title,
    start,
    end,
  } = props.appointment;
  const { handleClose } = props;

  const [isHover, setIsHover] = useState({ display: 'none' });
  const webexToken = useContext(AccessTokenContext);
  const [sipAddress, setSipAddress] = useContext(SipAddressContext);

  const [appointments, setAppointments] = useContext(AppointmentContext);
  const date = new Date(start.slice(0, 10));
  date.setDate(date.getDate() + 1);

  const startDateObj = new Date(start);
  const endDateObj = new Date(end);

  const formattedStartTime = formatAMPM(startDateObj);
  const formattedEndTime = formatAMPM(endDateObj);

  const handleMouseEnter = () => {
    setIsHover({ display: 'block' });
  };

  const handleMouseLeave = () => {
    setIsHover({ display: 'none' });
  };

  // AppointmentControls
  const handleDeleteAppointment = () => {
    console.log(webexToken);
    //DeleteAppointmentByID(webexToken, id);

    DeleteAppointmentByID(webexToken, id).then(() => {
      return GetScheduledMeetingsByDay(webexToken);
    }).then(data => {
      setAppointments(data);
    });
  };

  const handleVideoConnect = () => {
    handleClose();
    setSipAddress(props.appointment.sipAddress);
  };
  // end AppointmentControls

  // appoinment detail popover
  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCopyMeetingInfo = () => {
    let str = `Meeting Info\nWhat:\n${title}\n\nWhen:\n${date}\n${formattedStartTime} - ${formattedEndTime}\n\nMeeting Link:\n${link}\n\nPassword:\n${password}`;
    copyText(str);
  }

  const handleInformation = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleInformationClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;
  // end appoinment detail popover

  return (
    <div>

      <Grid
        width="460px"
        marginLeft="0px"
        backgroundColor="#1275bc"
        container
        spacing="1"
        alignItems="center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        direction="row"

        sx={{
          px: 2,
          height: 40,
          width: 460,
          borderRadius: "0% 20px 20px 0%",
          backgroundColor: "#1275bc",
          float: 'left',
          mb: 2
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1 }}> {`${title}, ${formattedStartTime}`}</Typography>
        <AppointmentControls
          style={isHover}
          appointment={props.appointment}
          startTime={formattedStartTime}
          endTime={formattedEndTime}
          date={date.toDateString()}
          handleDeleteAppointment={handleDeleteAppointment}
          handleVideoConnect={handleVideoConnect}
          handleInformation={handleInformation}
          handleInformationClose={handleInformationClose}
          handleCopyMeetingInfo={handleCopyMeetingInfo}
          infoOpen={open}
        />
      </Grid>
    </div>
  );
}