import React, { useContext} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AuthContext from '../providers/AuthContext'
import SipAddressContext from '../providers/SipAddressContext';

const NavigationMenu = ({
    id,
    anchorEl,
    open,
    handleClose,
    onLogout
}) => {
    const [sipAddress, setSipAddress] = useContext(SipAddressContext);
    const [isAuth, setIsAuth] = useContext(AuthContext);

    const handleLogout = () => {
        setSipAddress(null);
        setIsAuth(false);
        onLogout();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            id={id}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    ml: 1,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleLogout}>
                <ListItemIcon >
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    )
}

export default NavigationMenu;