import React, { useEffect } from 'react';
import queryString from 'query-string';
import useWebexOAuth from './api/useWebexOAuth';
import { AccessTokenProvider } from './providers/AccessTokenContext';
import { SipAddressProvider } from './providers/SipAddressContext';
import { AppointmentProvider } from './providers/AppointmentContext';
import { AuthProvider } from './providers/AuthContext';
import './App.css';
import Main from './pages/main'


function App() {
  const { reloadToken } = queryString.parse(window.location.search);
  const [webexToken, setWebexToken] = useWebexOAuth(reloadToken);

  const handleLogout = () => {
    setWebexToken('');
    localStorage.setItem("loggedIn", false); // Assuming you're using localStorage to track login status

    const url = new URL(window.location.href);

    // Remove the hash and search params from the URL
    url.hash = '';
    url.search = '';

    // Navigate to the base URL path (without search params and hash)
    window.location.href = url.origin + url.pathname;
  };

  return (
    <AuthProvider>
      <AccessTokenProvider value={webexToken}>
        <SipAddressProvider>
          <AppointmentProvider >
            <Main onLogout={handleLogout} />
          </AppointmentProvider>
        </SipAddressProvider>
      </AccessTokenProvider>
    </AuthProvider>
  );
}

export default App;