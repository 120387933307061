import React, { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import BrandMark from "../brand/brandmark1.png";
import AuthContext from '../providers/AuthContext';
import styles from './login.module.css';

const Login = () => {
    const [isAuth, setIsAuth] = useContext(AuthContext);
    const [formState, setFormState] = useState({
        username: '',
        password: ''
    });

    const handleSubmit = () => {
        console.log(formState);
        if (formState.username === 'admin' && formState.password === 'password') {
            setIsAuth(true);
            localStorage.setItem("loggedIn", true);
        } else {
            console.log('wrong username & password');
        }
    };

    const handleChange = (e) => {
        setFormState(prevState => ({ ...prevState, [e.target.id]: e.target.value }));
    };

    return (
        <React.Fragment>
            <div className={styles.loginContainer}>
                <div className={styles.brandContainer}>
                    <img className={styles.brandmark} src={BrandMark} alt="Brand Mark" />
                </div>
                <div className={styles.formContainer}>
                    <h1 className={styles.logintitle}>Sign In</h1>
                    <p className={styles.loginsubtitle}>Welcome to Eklectus!</p>
                    <form id="login-form" className={styles.form}>
                        <TextField label="Username" id="username" variant="standard" onChange={handleChange} />
                        <TextField label="Password" id="password" variant="standard" onChange={handleChange} />
                        <Button onClick={handleSubmit} type="button" color="primary" className={styles.loginButton}>
                            Log in
                        </Button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login;
