import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import BrandMark from "../brand/brandmark.png";

import styles from './OAuthLink.module.css';

function OAuthLink({ clientID, loginText, redirectURI, webexAPIBaseURL, brandHeight }) {
  return (

    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', marginLeft: '27.5px' }}
    >
      <Link sx={{ display: 'table', textAlign: 'center' }} href={`${webexAPIBaseURL}?client_id=${clientID}&response_type=token&redirect_uri=${redirectURI}&scope=spark%3Aall%20spark%3Akms%20meeting%3Aschedules_read%20meeting%3Aschedules_write`}>
        <img style={{height: `${brandHeight}px`}}className={styles.brandmark} src={BrandMark} />
        <Typography className={styles.webexlink} align='center'>
          {loginText}
        </Typography>
      </Link>
    </Grid>
  );
}

OAuthLink.propTypes = {
  clientID: PropTypes.string.isRequired,
  loginText: PropTypes.string.isRequired,
  redirectURI: PropTypes.string.isRequired,
  webexAPIBaseURL: PropTypes.string.isRequired
};

export default OAuthLink;