import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WebexMeetingWidget } from '@webex/widgets';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import '@webex/components/dist/css/webex-components.css';
import '@webex/widgets/dist/webexWidgets.css';
import Auth from '../api/OAuthLink';
import useCurrentUri from '../util/useCurrentUri';
import BrandMark from "../brand/brandmark.png";
import styles from './WebexMeeting.module.css';

function WebexMeeting({ webexToken, destination }) {
  const redirectURI = useCurrentUri();
  const [brandHeight, setBrandHeight] = useState((window.innerHeight - 55) / 2)

  useEffect(() => {
    const handleWindowResize = () => {
            setBrandHeight((window.innerHeight - 55) / 2);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
}, []);
  return (
    <Box id="webex-container" sx={{width:'100%'}}>
      {destination && webexToken ?
        (
          <WebexMeetingWidget
            accessToken={webexToken}
            meetingDestination={destination}
          />
        )
        :
        (
          <div>
            {!webexToken ?
              <Auth
                clientID={process.env.REACT_APP_WEBEX_CLIENT_ID}
                loginText="To continue, please click here to authorize your Webex account."
                redirectURI={redirectURI}
                webexAPIBaseURL={process.env.REACT_APP_WEBEX_BASE_URL}
                brandHeight={`${brandHeight}`}
              />
              :
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ height: '100%', marginLeft: '27.5px' }}
              >
                <Box sx={{ display: 'table', textAlign: 'center' }}>
                  <img style={{height: `${brandHeight}px`}} className={styles.brandname} src={BrandMark} />
                  <Typography align='center'>
                    Please use the menu to connect to an appointment.
                  </Typography>
                </Box>
              </Grid>
            }
          </div>
        )
      }
    </Box>
  )
}

WebexMeeting.propTypes = {
  webexToken: PropTypes.string.isRequired
};

export default WebexMeeting;


