import React, { useState } from 'react'

const SipAddressContext = React.createContext(null);

export const SipAddressProvider = (props) => {
    const [sipAddress, setSipAddress] = useState(null);

    return (
        <SipAddressContext.Provider
            value={[
                sipAddress,
                setSipAddress
            ]}
        >
            {props.children}
        </SipAddressContext.Provider>
    )
}

export default SipAddressContext;