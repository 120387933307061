import React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import AppointmentDetailPopover from './appointmentDetailPopover';

export default function AppointmentControls(props) {
  const {
    style,
    infoOpen,
    anchorEl,
    appointment,
    startTime,
    endTime,
    date,
    handleDeleteAppointment,
    handleVideoConnect,
    handleInformation,
    handleInformationClose,
    handleCopyMeetingInfo
  } = props;

  return (
    <Stack style={style} direction="row" spacing={1}>
      <IconButton onClick={handleVideoConnect} aria-label="start video call">
        <VideoCallIcon />
      </IconButton>
      <IconButton onClick={handleDeleteAppointment} aria-label="delete">
        <DeleteIcon />
      </IconButton>
      <AppointmentDetailPopover
        anchorEl={anchorEl}
        title={appointment.title}
        password={appointment.password}
        startTime={startTime}
        endTime={endTime}
        date={date}
        link={appointment.sipAddress}
        open={infoOpen}
        handleCopyMeetingInfo={handleCopyMeetingInfo}
        handleClick={handleInformation}
        handleClose={handleInformationClose}
      />
    </Stack>
  );
}