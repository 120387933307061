import groupBy from '../../util/groupBy';

export const GetScheduledMeetingsByDay = async (webexToken) => {
    const date = item => item.start.slice(0, 10);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${webexToken}`
        }
    };

    let response = {};

    const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
    if (webexToken) {
        response = await fetch(`https://webexapis.com/v1/meetings?max=100&from=${yesterday.toISOString()}&to=2025-12-31T09%3A30%3A00`, requestOptions)
            .then(response => response.json())
            .then(data => groupBy(data.items, date));
    }
    return response;
}

export const DeleteAppointmentByID = async (webexToken, id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${webexToken}`
        },
    };

    if (webexToken) {
        const response = await fetch(`https://webexapis.com/v1/meetings/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Error deleting appointment');
        }
        return { success: true };
    }
    return { success: false };
}
